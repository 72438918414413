.backgroundImage {
  background-image: url("../img/bannerBGdesktop.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
  .backgroundImage {
    background-image: url("../img/bannerBGmobile.png");
    background-position: center;
    height: 75vh;
  }
}
